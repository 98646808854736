<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="abnormalRecords">
			<template #ops>
				<el-button v-click-blur style="width: 100px" type="primary" @click="handleExport">导出记录</el-button>
			</template>
		</gc-header>
		<div class="run-main">
			<div class="serach">
				<gc-custom-search
					v-for="item in searchCondition"
					:key="item.key"
					:keyWord="item.label"
					:filterable="item.filterable || false"
					:width="item.width || '300px'"
					:search.sync="form[item.key]"
					:type="item.type"
					:searchOption="options[item.key]"
					:needAllForSearch="item.needAllForSearch"
					:searchOptionKey="item.searchOptionKey"
				></gc-custom-search>
				<div class="serach-ops">
					<el-button type="primary" @click="getList(1, apiExceptionList)" v-click-blur>查 询</el-button>
					<el-button @click="reset(apiExceptionList)" v-click-blur>重 置</el-button>
				</div>
				<gc-column-setting
					:storageKey="`localcolumn-abnormal-records-${tenantType}`"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table-show">
				<gc-table
					:columns="columnList"
					:table-data="tableData"
					:border="true"
					:showPage="true"
					:total="page.total"
					:page-size="page.size"
					:currentPage="page.current"
					@current-page-change="
						currentPage => {
							currentPageChange(currentPage, apiExceptionList)
						}
					"
					@size-change="
						size => {
							page.size = size
							currentPageChange(1, apiExceptionList)
						}
					"
				></gc-table>
			</div>

			<gc-alarm-handle-dialog :show.sync="showHandleDialog" :detail="detail"></gc-alarm-handle-dialog>
		</div>
	</div>
</template>

<script>
import { apiExceptionList } from '@/apis/alarm.api.js'
import { exportReport } from '@/apis/statisticAnalysis.api'
import common from '../mixins/common.js'
import deviceTypeOption from '../mixins/deviceTypeOption.js'
import { isObject } from '@/utils/validate.js'
import { mapActions } from 'vuex'
import { deviceDetailPermissions } from '@/config/const'

export default {
	name: 'abnormalRecords',
	mixins: [common, deviceTypeOption],
	data() {
		return {
			form: {
				deviceNo: null, //设备编号
				firstCategory: null, //设备大类
				deviceTypeId: null, //设备类型
				exceptionType: null, //异常类型
				exceptionReason: null, //异常原因
				userType: null, //客户类型
			}, //筛选条件
			options: {}, //下拉列表
			showHandleDialog: false,
			detail: {},
			cancelRequest: true,
			columns: [],
		}
	},
	computed: {
		// 异常类型
		exceptionType() {
			return this.$store.getters.dataList.exceptionType || []
		},
		// 异常原因
		exceptionReason() {
			return this.$store.getters.dataList.exceptionReason || []
		},
		tenantType() {
			return this.$store.getters.userInfo.tenantType || '0'
		},
		searchCondition() {
			if (this.tenantType == 1) {
				// 水务
				return [
					{
						key: 'deviceNo',
						label: '设备编号',
					},
					{
						key: 'userType',
						label: '客户类型',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
					{
						key: 'exceptionType',
						label: '异常类型',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
					{
						key: 'exceptionReason',
						label: '异常原因',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
				]
			} else {
				return [
					{
						key: 'deviceNo',
						label: '设备编号',
					},
					{
						key: 'firstCategory',
						label: '设备类别',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'productCategoryId',
						},
					},
					{
						key: 'deviceTypeId',
						label: '设备类型',
						type: 'select',
						filterable: true,
						needAllForSearch: true,
						searchOptionKey: {
							label: 'deviceTypeName',
							value: 'deviceTypeId',
						},
					},
					{
						key: 'exceptionType',
						label: '异常类型',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
					{
						key: 'exceptionReason',
						label: '异常原因',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
				]
			}
		},
		getDeviceTypeFlag() {
			return this.tenantType == 1
		},
		columnList() {
			return this.columns.filter(o => o.selected)
		},
	},

	watch: {
		'form.firstCategory': {
			handler() {
				this.form.deviceTypeId = null
				this.getDeviceTypeOptions()
			},
		},

		tenantType: {
			handler(newVal) {
				this.columns = [
					{
						key: 'deviceNo',
						name: '设备编号',
						fixed: 'left',
						dragDisable: true,
						disabledSelect: true,
					},
					{
						key: 'firstCategoryName',
						name: '设备类别',
					},
					{
						key: 'deviceTypeName',
						name: '设备类型名称',
					},
					{
						key: 'userNo',
						name: '用户号',
					},
					{
						key: 'username',
						name: '客户/站点名称',
					},
					{
						key: 'userPhone',
						name: '联系电话',
					},
					{
						key: 'addressName',
						name: '安装地址',
						tooltip: true,
					},
					{
						key: 'exceptionType',
						name: '异常类型',
					},
					{
						key: 'exceptionReason',
						name: '异常原因',
					},
					{
						key: 'oprate',
						name: '操作',
						width: 250,
						render: (h, row) => {
							//  自定义表格内容展示
							const childList = [
								this.$has('monitor:run:exception:update')
									? h(
											'el-button',
											{
												props: {
													type: 'text',
													size: 'medium',
												},
												on: {
													click: () => {
														this.handleLog(row)
													},
												},
											},
											'处理登记',
									  )
									: '',
								this.$has(deviceDetailPermissions)
									? h(
											'el-button',
											{
												props: {
													type: 'text',
													size: 'medium',
												},
												on: {
													click: () => {
														this.$router.push({
															name: 'deviceDetail',
															query: {
																deviceId: row.deviceId,
															},
															params: {
																name: 'BasicInfo',
															},
														})
													},
												},
											},
											'查看详情',
									  )
									: '',
							]
							const hasVNode = childList.some(item => isObject(item))
							return h('div', {}, hasVNode ? childList : '/')
						},
						fixed: 'right',
					},
				]
				if (newVal === '1') {
					this.columns.splice(1, 2, {
						key: 'userTypeName',
						name: '客户类型',
					})
				}
			},
			immediate: true,
		},
	},

	mounted() {
		this.firstEnter = false
		console.log('mounted')
		if (this.$route.params?.firstCategory) {
			this.form.firstCategory = this.$route.params.firstCategory
		}
		if (this.tenantType != 1) {
			this.getFirstCategory().then(data => {
				this.$set(this.options, 'firstCategory', data)
			})
		}
		this.$set(this.options, 'exceptionType', this.exceptionType)
		this.$set(this.options, 'exceptionReason', this.exceptionReason)
		this.$set(this.options, 'userType', this.$store.getters.dataList.userType || [])
		this.getList(1, apiExceptionList)
	},

	methods: {
		...mapActions({
			getFirstCategory: 'common/getFirstCategory',
			getDeviceTypes: 'common/getAllDeviceTypeList',
		}),
		getDeviceTypeOptions() {
			this.getDeviceTypes({
				firstCategory: this.form.firstCategory || '',
			}).then(data => {
				this.$set(this.options, 'deviceTypeId', data)
			})
		},
		apiExceptionList,
		// 处理登记
		handleLog(row) {
			this.detail = row
			this.showHandleDialog = true
		},
		handleExport() {
			if (!this.tableData.length) {
				this.$message.warning('暂无表格数据')
				return
			}
			this.$toast('下载中，请稍后...')
			const data = {
				method: 'post',
				url: '/monitor/run/exception/export/excel',
				fileName: '异常记录.xlsx',
				data: this.form,
			}
			exportReport(data, 'excel', '', this.$toast)
		},
	},

	activated() {
		if (this.$route.params?.update) {
			this.form = this.$options.data().form
			this.form.firstCategory = this.$route.params.firstCategory
			this.getList(1, apiExceptionList)
		}
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
.serach-ops {
	margin-bottom: 14px;
}
</style>
